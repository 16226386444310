export default {
  async selectFormData (Vue, id) {
    return (await Vue.$offline.solicitudMaterial.row(id)).solicitud_material
  },
  async selectGruposUsuario (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.grupoEmpleado.select({
      where: tables.grupo_empleado.estado.gt(0)
    })
  },
}