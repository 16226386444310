<template>
  <b10-base>
    <b10-date-time-picker
      v-model="form.fsolicitud"
      title="Fecha y hora de solicitud"
      disabled
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="fsolicitada_entrega"
      :rules="formRules.fsolicitada_entrega"
    >
      <b10-date-picker
        v-model="form.fsolicitada_entrega"
        title="Fecha solicitada de entrega"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-autocomplete
      v-model="form.idgrupo_empleado"
      :items="gruposUsuario"
      item-value="grupo_empleado.idgrupo_empleado"
      item-text="grupo_empleado.descripcion"
      label="Grupo de usuarios gestor"
      clearable
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <v-text-field
        v-model="form.descripcion"
        clearable
        label="Descripción"
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.atencion_de"
      label="A la atención de"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './SolicitudMaterialFormData'
import { currentDate, toDate, currentDateTime } from '@/utils/date'
import  { extend } from 'vee-validate'

export default {
  mixins: [formMixin],
  props: {
    idparteTrabajo: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      form: {
        idprioridad: null,
        fsolicitud: currentDateTime(),
        fsolicitada_entrega: currentDate(),
        descripcion: null,
        observaciones: null,
        atencion_de: null,
        idgrupo_empleado: null,
      },
      formRules: {
        fsolicitada_entrega: { required: true, fechaPosterior: true },
        descripcion: { required: true },
      },
      gruposUsuario: [],
    }
  },
  async created () {
    extend('fechaPosterior', {
      message: 'La fecha de solicitud de entrega debe ser posterior a la de solicitud.',
      validate: async (value) => {
        if (value) {
          return toDate(value) >= toDate(this.form.fsolicitud)
        }
      }
    })
    this.gruposUsuario = await Data.selectGruposUsuario(this)
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'idgrupo_empleado', formData.idgrupo_empleado)
      this.form = formData
    },
  },
}
</script>
